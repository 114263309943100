import { Button, ButtonGroup } from "reactstrap";

const StatSelector = ({ league_specific_stats, selectedStat, setSelectedStat }) => {

    const handleStatChange = (stat) => {
        setSelectedStat(stat);
    }

    const btnGroups = [];
    for (const group in league_specific_stats) {
       
        const btns = league_specific_stats[group].map((s, j) => {
            const isSelected = selectedStat.stat_id === s.stat_id;

            return <Button className={`record-book-stat-btn ${isSelected ? 'active' : ''}`} outline key={j} color="primary" onClick={() => handleStatChange(s)}>{s.stat_display_name}</Button>
        })

        btnGroups.push(<ButtonGroup key={group}>{btns}</ButtonGroup>)       
    }

    return (
        <div className="text-align-center">
            {btnGroups}
        </div>
    );
};

export default StatSelector;