import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth-context";
import { UserLeagueDropdown } from "../user-leagues/user-league-dropdown";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import { useLeagueSelector } from "../../context/league-selector-context";
import { get_league_settings } from "../../api/yahoo-integration-info";
import { handleUnauthorized } from "../errors/handle-unauthorized"; 
import StatSelector from "./StatSelector";
import { TimeframeSelector } from "./TimeframeSelector";
import { RecordBookTable } from "./RecordBookTable";
import { get_league_record_book } from "../../api/record-book";

const RecordBookContainer = () => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();
    const [userLeagues, setUserLeagues] = useState([]);
    const [yahooLeagueSettings, setYahooLeagueSettings] = useState();
    const [timeframe, setTimeframe] = useState("Season");
    const [selectedStat, setSelectedStat] = useState({});
    const [recordBook, setRecordBook] = useState({});


    useEffect(() => {
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);

        if (selectedLeague) {
            const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_settings(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setYahooLeagueSettings(result.data);
                    const defaultStat = result.data.settings?.stat_categories.stats.stat[1];
                    if (defaultStat) {
                        setSelectedStat(defaultStat);
                    }
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }

            const getLeagueRecordBookAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_record_book(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setRecordBook(result.data);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }
            getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id);
            getLeagueRecordBookAsync(userToken, refreshUserToken, selectedLeague.league_id);
        }



    }, [selectedLeague]);

    // console.log(yahooLeagueSettings)

    if (!yahooLeagueSettings?.settings?.stat_categories) {
        console.log('no settings')
        return <div><UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown></div>
    }

    const stat_categories = yahooLeagueSettings.settings.stat_categories;


    const stat_groups = stat_categories.groups.group;
    const stat_group_names = stat_groups.map(x => x.group_name);

    const league_specific_stats = stat_group_names.map((group, index) => {

        const stat_group = stat_categories.stats.stat.filter(s => s.group === group);

        const league_stats = stat_group.map((s, j) => {
            if (s.is_only_display_stat == 1) {
                return null;
            }

            return {stat_id: s.stat_id, display_stat: s.is_only_display_stat, stat_name: s.name, stat_display_name: s.display_name, stat_group: s.group}
        })

        return league_stats.filter(x => x != null);
    })


    console.log(league_specific_stats)




    return (
        <div>
            <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
            <TimeframeSelector timeframe={timeframe} setTimeframe={setTimeframe}></TimeframeSelector>
            <StatSelector selectedStat={selectedStat} setSelectedStat={setSelectedStat} league_specific_stats={league_specific_stats}></StatSelector>
            <RecordBookTable selectedStat={selectedStat} recordBook={recordBook}></RecordBookTable>
        </div>
    )
};

export default RecordBookContainer;




