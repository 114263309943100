import { Col, Row } from "reactstrap";
import { WeeklyStatRankingTrendChart } from "./WeeklyStatRankingTrendChart";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { LeagueStatPieChart } from "./LeagueStatPieChart";
import { TeamStatRadarChart } from "./TeamStatRadarChart";

export const WeeklyStatBreakdown = (props) => {

    const { selectedStat, team_matchup, team_id, team_weekly_ranks, league_weekly_ranks, subset_selected_key, week_by_week_stats } = props;

    const selected_stat_or_default = selectedStat || { stat_id: 7 };

    const teamCount = league_weekly_ranks.length;

    const top_x_finishes = {};
    for (let i = 1; i <= teamCount; i++) {
        top_x_finishes[i] = 0;
    }

    const weeks_excluded = subset_selected_key == 'season' ? 0 : team_matchup.length - subset_selected_key.slice(1);
    const excluded_week_numbers = [];
    for (let i = 0; i < weeks_excluded; i++) {
        excluded_week_numbers.push(i);
    }

    const stat_specific_results = team_matchup?.map((m, index) => {
        const week = m.week;

        if (m.matchup_result == null) {
            return { week, result: null };
        }


        const matchup_result_for_stat_we_care_about = m.matchup_result.stat_winners.find(sw => sw.stat_id == selected_stat_or_default.stat_id);
        const wasTied = matchup_result_for_stat_we_care_about.is_tied == 1;
        let result = '';
        if (m.matchup_result.status === 'midevent') {
            result = null;
        } else if (wasTied) {
            result = 'tie';

        } else {
            const winner_key_parts = matchup_result_for_stat_we_care_about.winner_team_key.split('.');

            if (winner_key_parts.slice(-1) == team_id) {

                result = 'win';
            } else {

                result = 'loss';
            }
        }

        // ====== vs league each week ======
        const get_stat_we_care_about = (team, weekNumber, stat_id) => {
            const team_stats = team.team_stats_by_week;
            const this_weeks_stats = team_stats.find(w => w.week == weekNumber);
            const stat_of_interest = this_weeks_stats.weekly_stats.find(s => s.stat_id == stat_id);
            return stat_of_interest;
        };

        const get_stat_rank_for_stat_we_care_about = (team, weekNumber, stat_id) => {
            const stat_of_interest = get_stat_we_care_about(team, weekNumber, stat_id);
            return parseFloat(stat_of_interest.rank);
        };

        if (!excluded_week_numbers.includes(m.week)) {
            const my_team = league_weekly_ranks.find(t => t.team.team_id == team_id);
            const rank = get_stat_rank_for_stat_we_care_about(my_team, m.week, selected_stat_or_default.stat_id);
            top_x_finishes[rank] += 1;
        }



        return { week, result };
    });



    return (
        <div className='weekly-stat-breakdown'>

            <Row className="flex-nowrap horizontal-scroll ">

                <Col>
                    <TeamStatRadarChart stat_group='batting' subset_selected_key={subset_selected_key} team_id={team_id} week_by_week_stats={week_by_week_stats}></TeamStatRadarChart>
                </Col>
                <Col>
                    <TeamStatRadarChart stat_group='pitching' subset_selected_key={subset_selected_key} team_id={team_id} week_by_week_stats={week_by_week_stats}></TeamStatRadarChart>
                </Col>
                <Col>
                    <Row>
                        <strong className="text-align-center">{`${selectedStat?.name}`}</strong>
                    </Row>

                    <WeeklyStatRankingTrendChart subset_selected_key={subset_selected_key} stat_specific_results={stat_specific_results} team_weekly_ranks={team_weekly_ranks} selectedStat={selectedStat} league_weekly_ranks={league_weekly_ranks} team_id={team_id}></WeeklyStatRankingTrendChart>

                    <Row>
                        <Col></Col>
                        <PlayerStatDisplayCol label='1st' size='sm' value={top_x_finishes[1]}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol label='Top 3' size='sm' value={top_x_finishes[3] + top_x_finishes[2] + top_x_finishes[1]}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol label='Bottom 3' size='sm' value={top_x_finishes[teamCount] + top_x_finishes[teamCount - 1] + top_x_finishes[teamCount - 2]}></PlayerStatDisplayCol>
                        <Col></Col>
                    </Row>
                </Col>
                <Col className=''>
                    <Row>
                        <strong className="text-align-center">{`${selectedStat?.name}`}</strong>
                    </Row>
                    <LeagueStatPieChart selectedStat={selectedStat} subset_selected_key={subset_selected_key} team_id={team_id} week_by_week_stats={week_by_week_stats}></LeagueStatPieChart>
                </Col>


            </Row>

        </div>
    );
};
