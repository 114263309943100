import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from './stripe';
import getApi from '../api/axios/api';

let stripePromise;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export const createSubscription = async (paymentMethodId, planId, userToken, refreshUserToken) => {
  const api = getApi(userToken, refreshUserToken);
  const response = await api.post('/stripe/payments/create-subscription', {
    paymentMethodId,
    planId,
  });
  return response;
};

export const getSubscriptionStatus = async (userToken, refreshUserToken) => {
  const api = getApi(userToken, refreshUserToken);
  const response = await api.get('/stripe/subscription/status');
  return response;
};

export const cancelSubscription = async (userToken, refreshUserToken) => {
  const api = getApi(userToken, refreshUserToken);
  const response = await api.post('/stripe/subscription/cancel');
  return response;
};

export const recordUserSubscription = async (subscriptionId, userToken, refreshUserToken) => {
    const api = getApi(userToken, refreshUserToken);
    const response = await api.post('/stripe/subscription/record', {
        subscriptionId,
    });
    return response;
};