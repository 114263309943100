import { Row, Table } from "reactstrap";
import { get_game_result } from "../hitter-finder/GameLogView";

export const GameLogView = (props) => {

    const {pitcher} = props;
    const game_log = pitcher?.game_log;
    if (!game_log) {
        return <div></div>
    }

    if (!game_log.stat_categories?.stats?.stat || !game_log.game_log_stats_collection?.game_log_stats) {   
        return <div></div>
    }

    const stat_reference_info = game_log.stat_categories.stats.stat;
    const stat_order = stat_reference_info.map(s => s.stat_id);
    const stat_headers = stat_reference_info.map(x => {
        return <th className='sticky-top'>{x.abbr}</th>
    })

    const game_log_entries = game_log.game_log_stats_collection.game_log_stats;

    const ip_stat = stat_reference_info.find(x => x.display_name == 'IP');
    const ip_stat_id = ip_stat.stat_id;

    const games_with_ip = game_log_entries.filter(gl => {
        const ip_value = gl.stats.stat.find(x => x.stat_id == ip_stat_id);
        return typeof ip_value.value == 'string' && ip_value.value.includes('.')
    })

    const game_rows = games_with_ip.map(day => {
        const date =  day.date.slice(-5)
        const opponent = day.opponent;
        const isHomeGame = !opponent.includes('@');
        const games = day.games;

        const result = get_game_result(games);

        // now stats.... I'm sure there are in order... but should we trust it? Let's just match on id
        const stats = day.stats.stat;
        
        const ordered_stat_cells = stat_order.map(x => {
            const day_stat = stats.find(s => s.stat_id === x);
            return <td>{day_stat.value}</td>
        })

        const started_indicator = result === 'PPD' ? '' : opponent.includes('x') ? 'red' : 'green';

        return (
            <tr>
            <td>{date}</td>
            <td className={started_indicator}>{opponent}</td>
            <td>{result}</td>
            {ordered_stat_cells}
            </tr>
        )
    })

    return (
        <Row className="game-log-row">
            <Table className='game-log-table'>
                <thead>
                    <tr className=''>
                        <th className='sticky-top'>Date</th>
                        <th className='sticky-top'>Opp</th>
                        <th className='sticky-top'>Result</th>
                        {stat_headers}
                    </tr>
                </thead>
                <tbody>
                    {game_rows}
                </tbody>
            </Table>
        </Row>
    )
};
