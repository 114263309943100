import { Row, Col, Table } from "reactstrap";

export const RecordBookTable = ({selectedStat, recordBook}) => {

    console.log(recordBook)
  
    
    const records = recordBook[selectedStat.stat_id];

    console.log(records)

    if (!records) {
        return null;
    }

    const rows = records.map((record, index) => {
        return (
            <tr key={index}>
                <td>{record.value}</td>
                <td>{record.manager}</td>
                <td>{record.team_name}</td>
               
                
                <td>{record.week_number}</td>
                <td>{record.league_id == '56308' ? '2024' : 
                record.league_id == '92842' ? '2023' : 
                record.league_id == '82441' ? '2022' :
                record.league_id == '91838' ? '2021' :
                record.league_id == '125737' ? '2020' :
                '2019' 
                }</td>
                
            </tr>
        )
    })


    return (
        <Row>
            <Col>
            <Table size="sm" className="record-book-table">
                <thead>
                    <tr>
                        <th>{selectedStat.stat_display_name}</th>
                        <th>Manager</th>
                        <th>Team</th>
                        <th>Week</th>
                        <th>Year</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
            </Col>
        </Row>
           
       
    );
};
