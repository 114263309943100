import React from 'react';
import { Col, Row } from 'reactstrap';
import { plans } from './subscription-plans';

const SubscriptionSelector = ({ selectedPlan, onSelectPlan }) => {
    const subPlans = plans;    

    return (
        <div className="subscription-plans">
            <h2>Available Plans:</h2>
            <Row className="plans-grid">
                {subPlans.map(plan => (
                    <Col 
                        key={plan.id} 
                        className={`plan-card ${selectedPlan.id === plan.id ? 'current' : ''}`}
                    >
                        <h3>{plan.name}</h3>
                        <p className="price">${plan.price}/{plan.billingPeriod}</p>
                        <ul>
                            {plan.features.map(feature => (
                                <li key={feature}>{feature}</li>
                            ))}
                        </ul>
                        <button 
                            onClick={() => onSelectPlan(plan)}
                            disabled={selectedPlan.id === plan.id}
                        >
                            {selectedPlan.id === plan.id ? 'Selected' : 'Select Plan'}
                        </button>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default SubscriptionSelector;