import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { PlayerInfoHeader } from "../player-info/PlayerInfoHeader"
import { getLogoId } from "../../mlb-team-logos/logo-mapper";
import { PitcherInfoMultiView } from "./PitcherInfoMultiView";
import { useEffect, useState } from "react";
import { BasicStatsDisplay } from "../hitter-finder/BasicStatsDisplay";
import { useAuthContext } from "../../context/auth-context";
import { getTeamGameLog, getTeamHittingSplits, getTeamStatRankings } from "../../api/team-stats";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { getLeagueWideHitterStats } from "../../api/hitter-stats";
import { TeamIds } from "../../enums";
import AddDropModal from "../add-drop-scheduler/add-drop-modal/add-drop-modal";


export const MatchupDetails = (props) => {
    const { pitcher, toggle, gameInfo, league, rosters, leagueSettings } = props;
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const [viewSelected, setViewSelected] = useState('pitcher-stats');
    const [teamHittingStats, setTeamHittingStats] = useState();
    const [leagueWideHitterStats, setLeagueWideHitterStats] = useState([]);
    const [teamStatRankings, setTeamStatRankings] = useState([]);
    const [teamHittingSplits, setTeamHittingSplits] = useState([]);
    const [isAddDropModalOpen, setIsAddDropModalOpen] = useState(false);

    const toggleAddDropModal = () => setIsAddDropModalOpen(!isAddDropModalOpen)

    const leagueWideStats = { hitter: leagueWideHitterStats }

    const isHomePitcher = TeamIds[gameInfo.homeTeam.teamAbbr] == TeamIds[pitcher.editorial_team_abbr];
    const opposingTeamAbbr = (isHomePitcher ? gameInfo.awayTeam : gameInfo.homeTeam)?.teamAbbr;
    const opposingTeamLogoUrl = `https://www.mlbstatic.com/team-logos/${getLogoId(isHomePitcher ? gameInfo.awayTeam.teamAbbr : gameInfo.homeTeam.teamAbbr)}.svg`


    useEffect(() => {
        const getTeamStatRankingsAsync = async (userToken, refreshUserToken) => {
            const teamStatsResp = await getTeamStatRankings(userToken, refreshUserToken);
            if (teamStatsResp.success) {
                setTeamStatRankings(teamStatsResp.data);
            } else {
                handleUnauthorized(teamStatsResp.error, logout)
                console.error(teamStatsResp.error)
            }
        }

        if (!teamStatRankings.length > 0) {
            getTeamStatRankingsAsync(userToken, refreshUserToken);
        }

        

        const getTeamHittingSplitsAsync = async (userToken, refreshUserToken) => {
            const teamStatsResp = await getTeamHittingSplits(userToken, refreshUserToken);
            if (teamStatsResp.success) {
                setTeamHittingSplits(teamStatsResp.data);
            } else {
                handleUnauthorized(teamStatsResp.error, logout)
                console.error(teamStatsResp.error)
            }
        }
        if (!teamHittingSplits.length > 0) {

            getTeamHittingSplitsAsync(userToken, refreshUserToken);
        }


        if (opposingTeamAbbr) {
            const getTeamHittingStatsAsync = async (userToken, refreshUserToken, teamAbbr) => {
                const teamStatsResp = await getTeamGameLog(userToken, refreshUserToken, teamAbbr);
                if (teamStatsResp.success) {
                    setTeamHittingStats(teamStatsResp.data);
                } else {
                    handleUnauthorized(teamStatsResp.error, logout)
                    console.error(teamStatsResp.error)
                }
            }

            getTeamHittingStatsAsync(userToken, refreshUserToken, opposingTeamAbbr);

            const getLeagueWideHitterStatsAsync = async (userToken, refreshUserToken) => {
                const result = await getLeagueWideHitterStats(userToken, refreshUserToken);
                if (result.success) {
                    setLeagueWideHitterStats(result.data);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error);
                }
            }

            getLeagueWideHitterStatsAsync(userToken, refreshUserToken);
        }


    }, [])

    const oppBattingOrder = isHomePitcher ? gameInfo.awayBattingOrder : gameInfo.homeBattingOrder;
    const oppTeamSplits = filterByTeamAbbr(teamHittingSplits, opposingTeamAbbr);

    const statRankings = teamStatRankings.filter(x => x.mlbteamid == TeamIds[opposingTeamAbbr]);

    const opposingTeam = { battingOrder: oppBattingOrder, teamLogoUrl: opposingTeamLogoUrl, teamHittingStats, statRankings, splits: oppTeamSplits }

    return (
        <>
        <AddDropModal leagueSettings={leagueSettings} player={pitcher} toggle={toggleAddDropModal} league={league} isOpen={isAddDropModalOpen} roster={rosters?.futureRoster}></AddDropModal>
        <Col className="game-tile">
            <Row className="bg-light-gray padding-5">
                <Col><img className="hitting-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(gameInfo.awayTeam.teamAbbr)}.svg`}></img></Col>
                <Col>@</Col>
                <Col><img className="hitting-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(gameInfo.homeTeam.teamAbbr)}.svg`}></img></Col>

                <Col>
                    <FontAwesomeIcon className="pull-right" color="" icon={faTimes} size="1x" onClick={toggle}></FontAwesomeIcon>
                </Col>
            </Row>
            <Row className="padding-5">
                <Col>
                    <PlayerInfoHeader player={pitcher} openAddDrop={toggleAddDropModal}></PlayerInfoHeader>
                </Col>
            </Row>
            <Row>
                <BasicStats stats={pitcher.stats}></BasicStats>

            </Row>
            <Row>
                <PitcherInfoMultiView onClick={setViewSelected} view={viewSelected} pitcher={pitcher} opposingTeam={opposingTeam} leagueWideStats={leagueWideStats}></PitcherInfoMultiView>

            </Row>
        </Col>
        </>
    )
}

function filterByTeamAbbr(data, teamAbbr) {
    let filteredAll = [];
    let filteredVsR = [];
    let filteredVsL = [];

    data.forEach(category => {
        if (category.hasOwnProperty('all')) {
            filteredAll = category.all.filter(entry => TeamIds[entry.TeamNameAbb] === TeamIds[teamAbbr]);
        } else if (category.hasOwnProperty('vs R')) {
            filteredVsR = category['vs R'].filter(entry => TeamIds[entry.TeamNameAbb] === TeamIds[teamAbbr]);
        } else if (category.hasOwnProperty('vs L')) {
            filteredVsL = category['vs L'].filter(entry => TeamIds[entry.TeamNameAbb] === TeamIds[teamAbbr]);
        }
    });

    return { all: filteredAll[0], vs_r: filteredVsR[0], vs_l: filteredVsL[0] };
}


const BasicStats = (props) => {

    const { stats } = props;
    const basicStatsToShow = [
        {
            key: 'IP',
            display: 'IP',

        },
        {
            key: 'ERA',
            display: 'ERA',
            predisplay_func: (val) => { return val?.toFixed(2) || 0 }

        },
        {
            key: 'AVG',
            display: 'BAA',
            predisplay_func: (val) => { return val?.toFixed(3).toString().substring(1) || 0 }

        },
        {
            key: 'SO',
            display: 'K',

        },

    ]

    const leagueWideStats = { pitcher: {} };
    return (
        <BasicStatsDisplay statsToShow={basicStatsToShow} stats={stats} leagueWidePitcherStats={leagueWideStats.pitcher}></BasicStatsDisplay>
    )
}