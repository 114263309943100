import { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createSubscription } from './stripe-service';
import { useAuthContext } from '../context/auth-context';

const CheckoutForm = ({ planId, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const { userToken, refreshUserToken } = useAuthContext();

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        try {
            // Create payment method
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (error) {
                onError(error.message);
                return;
            }

            // Send to your backend
            const response = await createSubscription(paymentMethod.id, planId, userToken, refreshUserToken);

            const data = response.data;
            if (data.error) {
                onError(data.error);
                return;
            }

            // Handle subscription activation
            if (data.status == 'incomplete') {
                const { error: confirmationError } = await stripe.confirmCardPayment(
                    data.clientSecret
                );
                
                if (confirmationError) {
                    onError(confirmationError.message);
                    return;
                }
            }

            onSuccess(data);
        } catch (error) {
            onError('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="checkout-form">
            <div className="card-element-container">
                <CardElement 
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>
            <button 
                type="submit" 
                disabled={!stripe || loading}
                className="submit-button"
            >
                {loading ? 'Processing...' : 'Subscribe'}
            </button>
        </form>
    );
};

export default CheckoutForm;