import { PRODUCT_PRICES } from "../../stripe/stripe";

export const plans = [
    // {
    //     id: PRODUCT_PRICES.BASIC,
    //     name: 'Basic',
    //     price: 9.99,
    //     features: ['Feature 1', 'Feature 2', '','']
    // },
    // {
    //     id: PRODUCT_PRICES.PRO,
    //     name: 'Premium',
    //     price: 19.99,
    //     features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4']
    // },

    {
        id: PRODUCT_PRICES.WEEKLY,
        name: 'Weekly',
        price: 6.99,
        billingPeriod: 'week',
        features: []
    },
    {
        id: PRODUCT_PRICES.MONTHLY,
        name: 'Monthly',
        price: 19.99,
        billingPeriod: 'month',
        features: []
    },
    {
        id: PRODUCT_PRICES.SEASON,
        name: 'Season',
        price: 99.99,
        billingPeriod: 'year',
        features: []
    }
];