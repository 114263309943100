export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51QZhl2L4rWFCsbXSqyvrFLzbxXnIBblIuDsUGt8bC2TOuZkE6ljSLw8AB4dL0bg8IYTrp2JMS70I5ht2mozPuWP500Cw5XsWQ0';
export const PRODUCT_PRICES = {
  BASIC: 'price_1QZyWyL4rWFCsbXSLoDTjtDL',
  PRO: 'price_1QZyXGL4rWFCsbXSy3cgyBlg',
  WEEKLY: 'price_1QahjEL4rWFCsbXS11giMsHB',
  MONTHLY: 'price_1QahkWL4rWFCsbXSh3kkkAqj',
  SEASON: 'price_1QahlBL4rWFCsbXSnnLqUm1m',
};

// export const STRIPE_PUBLISHABLE_KEY = 'process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY';

// export const PRODUCT_PRICES = {
//     BASIC: process.env.REACT_APP_STRIPE_BASIC_PRICE_ID,
//     PRO: process.env.REACT_APP_STRIPE_PRO_PRICE_ID
//   };