import { Button, ButtonGroup } from "reactstrap";

export const TimeframeSelector = ({timeframe, setTimeframe}) => {

    const timeframes = ['Daily', 'Matchup', 'Season', 'All Time'];

    const handleTimeframeChange = (timeframe) => {
        setTimeframe(timeframe);
    }

    

    return (
        <div className="text-align-center timeframe-selector">
            <ButtonGroup>
                {timeframes.map((t, j) => {
                    const isSelected = timeframe === t;
                    return <Button className={`record-book-timeframe-btn ${isSelected ? 'active' : ''}`} outline key={j} color="primary" onClick={() => handleTimeframeChange(t)}>{t}</Button>
                })}
            </ButtonGroup>
        </div>
    );
};
